// CallRequests.js

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
  Suspense,
} from 'react';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Badge,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Pagination,
  Snackbar,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  FilterList as FilterListIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { CallRequestContext } from '../contexts/CallRequestContext';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Lazy-loaded components
const CallRequestsTable = React.lazy(() =>
  import('./CallRequests/CallRequestsTable')
);
const FeedbackDialog = React.lazy(() =>
  import('./CallRequests/FeedbackDialog')
);

dayjs.extend(utc);
dayjs.extend(timezone);

// ### Subcomponents ###

// Filters Component
const Filters = React.memo(
  ({
    filter,
    handleFilterChange,
    handleClearFilter,
    selectedLocation,
    handleLocationChange,
    availableLocations,
    statusFilter,
    handleStatusFilterChange,
    unhandledCount,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    adapterLocale,
  }) => {
    const { t } = useTranslation();

    return (
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Location Selection */}
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="location-select-label">{t('select_location')}</InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                name="location"
                value={selectedLocation || ''}
                onChange={handleLocationChange}
                label={t('select_location')}
                aria-label={t('select_location')}
              >
                {availableLocations.length > 1 && (
                  <MenuItem value="">
                    <em>{t('all_locations')}</em>
                  </MenuItem>
                )}
                {availableLocations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Text Filter */}
          <Grid item xs={12} md={3}>
            <TextField
              id="text-filter"
              name="filter"
              label={t('filter_placeholder')}
              variant="outlined"
              onChange={handleFilterChange}
              fullWidth
              value={filter}
              InputProps={{
                startAdornment: <FilterListIcon />,
                endAdornment: filter && (
                  <IconButton onClick={handleClearFilter} aria-label={t('clear_filter')}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              aria-label={t('filter_placeholder')}
            />
          </Grid>

          {/* Status Filter */}
          <Grid item xs={12} md={3}>
            <FormControl component="fieldset" fullWidth>
              <ToggleButtonGroup
                name="statusFilter"
                value={statusFilter}
                exclusive
                onChange={handleStatusFilterChange}
                aria-label={t('status_filter')}
                fullWidth
                color="primary"
              >
                <ToggleButton value="all">{t('show_all')}</ToggleButton>
                <ToggleButton value="unhandled">
                  {t('pending')}
                  {unhandledCount > 0 && (
                    <Badge badgeContent={unhandledCount} color="error" sx={{ ml: 1 }} />
                  )}
                </ToggleButton>
                <ToggleButton value="handled">{t('handled')}</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Grid>

          {/* Date Pickers Container */}
          <Grid item xs={12} md={3}>
            <Grid container spacing={2}>
              {/* Start Date */}
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
                  <DatePicker
                    label={t('start_date')}
                    value={startDate}
                    onChange={setStartDate}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    maxDate={endDate} // Ensure startDate does not exceed endDate
                    aria-label={t('start_date')}
                  />
                </LocalizationProvider>
              </Grid>

              {/* End Date */}
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
                  <DatePicker
                    label={t('end_date')}
                    value={endDate}
                    onChange={setEndDate}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    minDate={startDate} // Ensure endDate is not before startDate
                    aria-label={t('end_date')}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

// NoteDialog Component
const NoteDialog = React.memo(
  ({
    open,
    handleClose,
    selectedRequest,
    noteText,
    setNoteText,
    handleSaveNote,
    handleRemoveNote,
  }) => {
    const { t } = useTranslation();

    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="note-dialog-title">
        <DialogTitle id="note-dialog-title">
          {selectedRequest && selectedRequest.comments ? t('remove_note') : t('add_note')}
        </DialogTitle>
        <DialogContent>
          {selectedRequest && selectedRequest.comments ? (
            <Typography variant="body1">{selectedRequest.comments}</Typography>
          ) : (
            <TextField
              id="note-text"
              name="note"
              label={t('note')}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              sx={{ mb: 2 }}
              aria-label={t('note')}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          {selectedRequest && selectedRequest.comments ? (
            <Button onClick={handleRemoveNote} color="error" variant="contained">
              {t('remove_note')}
            </Button>
          ) : (
            <Button
              onClick={handleSaveNote}
              color="primary"
              variant="contained"
              disabled={!noteText.trim()}
            >
              {t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

// ConfirmationDialog Component
const ConfirmationDialog = React.memo(({ open, handleClose, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog-title">
      <DialogTitle id="confirm-dialog-title">{t('confirm_unmark_handled')}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

// SMSConversationDialog Component
const SMSConversationDialog = React.memo(
  ({
    open,
    handleClose,
    conversation,
    sendMessage,
    newMessage,
    setNewMessage,
    t,
    loadingMessages,
  }) => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="sms-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="sms-dialog-title">{t('sms_conversation')}</DialogTitle>
        <DialogContent>
          {loadingMessages ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
              {conversation.length === 0 && (
                <Typography variant="body2" color="textSecondary">
                  {t('no_messages')}
                </Typography>
              )}
              {conversation.map((message) => (
                <React.Fragment key={message.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={message.from_number}
                      secondary={
                        <>
                          {message.body}
                          <Typography variant="caption" display="block" color="textSecondary">
                            {dayjs.utc(message.timestamp).format('DD.MM.YYYY HH:mm:ss')}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
          <TextField
            fullWidth
            variant="outlined"
            label={t('write_message')}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            multiline
            rows={2}
            sx={{ mb: 1 }}
            aria-label={t('write_message')}
          />
          <Button
            onClick={sendMessage}
            color="primary"
            variant="contained"
            disabled={!newMessage.trim()}
          >
            {t('send')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

// ConfirmReleaseDialog Component
const ConfirmReleaseDialog = React.memo(({ open, handleClose, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-release-dialog-title">
      <DialogTitle id="confirm-release-dialog-title">{t('confirm_release_reservation')}</DialogTitle>
      <DialogContent>
        <Typography>{t('confirm_release_message')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {t('release')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

// ### Main Component ###
const CallRequests = () => {
  const { t, i18n } = useTranslation();

  // Get callRequests and unhandledCount from context
  const {
    callRequests,
    setCallRequests,
    unhandledCount,
    unreadSmsRequests,
    setUnreadSmsRequests,
    subscribe,
  } = useContext(CallRequestContext);

  // ### State Hooks ###
  const [filter, setFilter] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [availableLocations, setAvailableLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmReleaseDialogOpen, setConfirmReleaseDialogOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [orderBy, setOrderBy] = useState('request_time');
  const [order, setOrder] = useState('desc');
  const [otherUnhandledRequests, setOtherUnhandledRequests] = useState([]);
  const [markAllAsHandled, setMarkAllAsHandled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const theme = useTheme();

  // Uudet tilat SMS-keskustelua varten
  const [smsDialogOpen, setSmsDialogOpen] = useState(false);
  const [conversations, setConversations] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [loadingMessages, setLoadingMessages] = useState(false);

  // ### Contexts ###
  const authToken = localStorage.getItem('token');
  const currentUsername = localStorage.getItem('username');

  // ### Date Pickers ###
  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  // Päivitä dayjs locale ja LocalizationProvider adapterLocale
  useEffect(() => {
    const currentLanguage = i18n.language || 'fi';
    dayjs.locale(currentLanguage);
  }, [i18n.language]);

  // Määritä adapterLocale dynaamisesti
  const adapterLocale = i18n.language === 'en' ? 'en' : 'fi';

  // ### Helper Functions ###
  const normalizePhoneNumber = useCallback((number) => {
    return number.replace(/\D/g, ''); // Remove all non-digit characters
  }, []);

  // Väripaletti käyttäjille
  const userColorsRef = useRef({});

  const colorPalette = useMemo(
    () => [
      theme.palette.primary.light,
      theme.palette.secondary.light,
      theme.palette.success.light,
      theme.palette.warning.light,
      theme.palette.info.light,
      theme.palette.error.light,
      theme.palette.grey[200],
      theme.palette.grey[300],
      theme.palette.grey[400],
    ],
    [theme]
  );

  const getColorForUser = useCallback(
    (username) => {
      const userColors = userColorsRef.current;
      if (!userColors[username]) {
        const color = colorPalette[Object.keys(userColors).length % colorPalette.length];
        userColors[username] = color;
      }
      return userColors[username];
    },
    [colorPalette]
  );

  const getRowBackgroundColor = useCallback(
    (request) => {
      if (request.reserved_by === currentUsername) {
        return theme.palette.action.selected; // Käyttäjän oma varaus
      } else if (request.reserved_by) {
        return getColorForUser(request.reserved_by); // Muiden varaukset
      } else {
        return theme.palette.background.paper; // Tavallinen rivi
      }
    },
    [currentUsername, getColorForUser, theme]
  );

  // ### useMemo Hooks ###

  // Final filtering and sorting
  const finalFilteredCallRequests = useMemo(() => {
    let filtered = callRequests;

    // Apply status filter
    if (statusFilter === 'handled') {
      filtered = filtered.filter((r) => r.status === 'handled');
    } else if (statusFilter === 'unhandled') {
      filtered = filtered.filter((r) => r.status === 'pending' || r.status === 'unhandled');
    }

    // Apply text filter
    if (filter) {
      filtered = filtered.filter(
        (request) =>
          (request.caller_name && request.caller_name.toLowerCase().includes(filter)) ||
          (request.caller_number && request.caller_number.toLowerCase().includes(filter)) ||
          (request.called_number && request.called_number.toLowerCase().includes(filter))
      );
    }

    // Apply date filter
    filtered = filtered.filter((request) => {
      const callDate = dayjs(request.request_time);
      if (startDate && callDate.isBefore(dayjs(startDate).startOf('day'))) return false;
      if (endDate && callDate.isAfter(dayjs(endDate).endOf('day'))) return false;
      return true;
    });

    // Apply location filter
    if (selectedLocation) {
      filtered = filtered.filter((r) => r.location_id === selectedLocation);
    }

    // Sort
    const sorted = [...filtered].sort((a, b) => {
      let aValue, bValue;
      switch (orderBy) {
        case 'caller_number':
          aValue = a.caller_number.toLowerCase();
          bValue = b.caller_number.toLowerCase();
          break;
        case 'called_number':
          aValue = a.called_number.toLowerCase();
          bValue = b.called_number.toLowerCase();
          break;
        case 'request_time':
        default:
          aValue = dayjs(a.request_time).unix();
          bValue = dayjs(b.request_time).unix();
      }

      if (order === 'asc') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return sorted;
  }, [
    callRequests,
    filter,
    statusFilter,
    startDate,
    endDate,
    order,
    orderBy,
    selectedLocation,
  ]);

  // Get current page call requests
  const currentCallRequests = useMemo(() => {
    const startIdx = (currentPage - 1) * 20;
    const endIdx = startIdx + 20;
    return finalFilteredCallRequests.slice(startIdx, endIdx);
  }, [finalFilteredCallRequests, currentPage]);

  // Group call requests by date
  const groupedCallRequests = useMemo(() => {
    const groups = {};
    currentCallRequests.forEach((request) => {
      const timezone = request.location?.timezone || 'UTC';
      const date = dayjs.utc(request.request_time).tz(timezone).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(request);
    });
    return groups;
  }, [currentCallRequests]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(finalFilteredCallRequests.length / 20); // rowsPerPage = 20
  }, [finalFilteredCallRequests.length]);

  // ### useEffect Hooks ###

  // Fetch locations for the user
  useEffect(() => {
    let isMounted = true; // Prevent state updates if component is unmounted
    const fetchLocations = async () => {
      try {
        const locationsResponse = await axios.get('/api/locations', {
          headers: { Authorization: `Bearer ${authToken}` },
        });

        if (isMounted) {
          setAvailableLocations(locationsResponse.data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
        if (isMounted) setError(t('error_fetching_locations'));
        setSnackbar({
          open: true,
          message: t('error_fetching_locations'),
          severity: 'error',
        });
        setLoading(false);
      }
    };
    fetchLocations();

    return () => {
      isMounted = false;
    };
  }, [authToken, t]);

  // Fetch call requests
  const fetchCallRequests = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/call_requests', {
        params: {
          locations: selectedLocation ? [selectedLocation] : [],
          start_date: startDate ? startDate.toISOString() : undefined,
          end_date: endDate ? endDate.toISOString() : undefined,
        },
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCallRequests(response.data); // Update context's callRequests
      setLoading(false);
    } catch (error) {
      console.error('Error fetching call requests:', error);
      setError(t('error_fetching_call_requests'));
      setSnackbar({
        open: true,
        message: t('error_fetching_call_requests'),
        severity: 'error',
      });
      setLoading(false);
    }
  }, [selectedLocation, startDate, endDate, authToken, t, setCallRequests]);

  // Fetch call requests when filters change
  useEffect(() => {
    fetchCallRequests();
  }, [fetchCallRequests]);

  // Handler for receiving new SMS messages via WebSocket
  useEffect(() => {
    const handleNewSmsMessage = (data) => {
      console.log('Received new SMS message via WebSocket:', data);
      const requestId = data.request_id;
      const direction = data.direction; // 'inbound' tai 'outbound'

      if (!requestId) {
        console.warn('Received SMS without request_id');
        return;
      }

      if (direction === 'inbound') {
        // Vain inbound-viestit merkitään lukemattomiksi
        setUnreadSmsRequests((prevSet) => new Set(prevSet).add(requestId));
      }

      // Construct the new message object
      const newMsg = {
        id: data.id,
        direction: direction,
        body: data.body,
        timestamp: data.timestamp,
        from_number: direction === 'inbound' ? data.from_number : currentUsername,
      };

      // Update conversations without adding duplicates
      setConversations((prev) => {
        const existingMessages = prev[requestId] || [];

        // Check for duplicates by comparing message IDs
        const isDuplicate = existingMessages.some((msg) => msg.id === newMsg.id);

        if (isDuplicate) {
          // Message is a duplicate; do not add it again
          return prev;
        }

        return {
          ...prev,
          [requestId]: [...existingMessages, newMsg],
        };
      });
    };

    const unsubscribe = subscribe('new_sms_message', handleNewSmsMessage);

    return () => {
      unsubscribe();
    };
  }, [subscribe, currentUsername, setUnreadSmsRequests]);

  // ### Event Handlers ###

  const handlePageChange = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  const handleFilterChange = useCallback(
    (e) => {
      setFilter(e.target.value.toLowerCase());
      setCurrentPage(1); // Reset page
    },
    []
  );

  const handleClearFilter = useCallback(() => {
    setFilter('');
    setCurrentPage(1); // Reset page
  }, []);

  const handleLocationChange = useCallback(
    (event) => {
      setSelectedLocation(event.target.value);
      setCurrentPage(1); // Reset page
    },
    []
  );

  const handleStatusFilterChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        setStatusFilter(newValue);
        setCurrentPage(1); // Reset page
      }
    },
    []
  );

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order]
  );

  // Handle opening note dialog
  const handleOpenNoteDialog = useCallback((request) => {
    setSelectedRequest(request);
    setNoteText(request.comments || '');
    setNoteDialogOpen(true);
  }, []);

  // Handler for opening SMS dialog
  const handleOpenSmsDialog = useCallback(
    async (request) => {
      setSelectedRequest(request);
      setSmsDialogOpen(true);
      setLoadingMessages(true);
      try {
        // Fetch the conversation messages
        const response = await axios.get('/sms/conversation', {
          params: {
            request_id: request.id,
          },
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setConversations((prev) => ({ ...prev, [request.id]: response.data }));
        setLoadingMessages(false);

        // Remove request.id from unreadSmsRequests set
        setUnreadSmsRequests((prevSet) => {
          const newSet = new Set(prevSet);
          newSet.delete(request.id);
          console.log('Removed requestId from unreadSmsRequests:', request.id, Array.from(newSet));
          return newSet;
        });
      } catch (error) {
        console.error('Error fetching SMS conversation:', error);
        setError(t('error_fetching_sms_conversation'));
        setSnackbar({
          open: true,
          message: t('error_fetching_sms_conversation'),
          severity: 'error',
        });
        setLoadingMessages(false);
      }
    },
    [authToken, t, setUnreadSmsRequests]
  );

  // Handler for sending new SMS message
  const sendMessage = useCallback(async () => {
    if (!selectedRequest) return;
    try {
      const messageData = {
        to_number: selectedRequest.caller_number,
        body: newMessage,
        location_id: selectedRequest.location_id,
        request_id: selectedRequest.id,
      };
      const response = await axios.post('/sms/send', messageData, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      console.log('Send message response:', response.data);

      // Oletetaan, että vastaus sisältää lähetetyn viestin tiedot
      const sentMessage = {
        id: response.data.id || Date.now(),
        direction: 'outbound',
        body: newMessage,
        timestamp: dayjs().toISOString(),
        from_number: currentUsername,
      };

      // Lisää lähetetty viesti heti keskusteluun
      setConversations((prev) => ({
        ...prev,
        [selectedRequest.id]: [...(prev[selectedRequest.id] || []), sentMessage],
      }));

      setNewMessage('');

      // Varmista, ettei request.id ole lukemattomien joukossa
      setUnreadSmsRequests((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.delete(selectedRequest.id);
        return newSet;
      });

      // Näytä onnistumisen ilmoitus
      setSnackbar({ open: true, message: t('sms_sent_successfully'), severity: 'success' });
    } catch (error) {
      console.error('Error sending SMS:', error);
      setError(t('error_sending_sms'));
      setSnackbar({ open: true, message: t('error_sending_sms'), severity: 'error' });
    }
  }, [
    selectedRequest,
    newMessage,
    authToken,
    t,
    currentUsername,
    setUnreadSmsRequests,
  ]);

  // Handle marking requests as handled
  const handleMarkAsHandled = useCallback(
    (request) => {
      setSelectedRequest(request);
      setFeedback('');

      const callerNumber = request.caller_number; // Get the caller number

      // Find other unhandled requests from the same caller number
      const otherRequests = callRequests.filter(
        (r) =>
          r.caller_number === callerNumber &&
          (r.status === 'pending' || r.status === 'unhandled') &&
          r.id !== request.id
      );

      if (otherRequests.length > 0) {
        setOtherUnhandledRequests(otherRequests);
      } else {
        setOtherUnhandledRequests([]);
      }

      if (request.status === 'handled') {
        setConfirmDialogOpen(true);
      } else {
        setFeedbackDialogOpen(true);
      }
    },
    [callRequests]
  );

  const confirmUnmarkHandled = useCallback(async () => {
    try {
      const updatedRequest = {
        status: 'unhandled',
        handled_by: null,
        handled_time: null,
        feedback: '',
      };
      const response = await axios.put(`/api/call_requests/${selectedRequest.id}`, updatedRequest, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? response.data : r))
      );
      setConfirmDialogOpen(false);
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('unmarked_as_handled'), severity: 'success' });
    } catch (error) {
      console.error('Error updating call request:', error);
      setError(t('error_updating_call_request'));
      setSnackbar({
        open: true,
        message: t('error_updating_call_request'),
        severity: 'error',
      });
      setConfirmDialogOpen(false);
    }
  }, [selectedRequest, authToken, setCallRequests, t]);

  const handleSaveFeedback = useCallback(async () => {
    try {
      const requestsToUpdate = [selectedRequest];

      if (markAllAsHandled && otherUnhandledRequests.length > 0) {
        requestsToUpdate.push(...otherUnhandledRequests);
      }

      // Päivitetään kaikki puhelupyynnöt
      const updates = requestsToUpdate.map(async (requestToUpdate) => {
        const updatedRequest = {
          status: 'handled',
          handled_by: currentUsername,
          handled_time: dayjs().toISOString(),
          feedback: feedback,
        };
        const response = await axios.put(
          `/api/call_requests/${requestToUpdate.id}`,
          updatedRequest,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );

        // Vapautetaan varaus erillisellä API-kutsulla
        await axios.post(`/api/call_requests/${requestToUpdate.id}/release`, null, {
          headers: { Authorization: `Bearer ${authToken}` },
        });

        // Päivitetään paikallinen tila
        return { ...response.data, reserved_by: null };
      });

      const updatedRequests = await Promise.all(updates);

      setCallRequests((prevRequests) =>
        prevRequests.map((r) => updatedRequests.find((u) => u.id === r.id) || r)
      );

      setFeedbackDialogOpen(false);
      setSelectedRequest(null);
      setMarkAllAsHandled(false);
      setOtherUnhandledRequests([]);
      setSnackbar({ open: true, message: t('marked_as_handled'), severity: 'success' });
    } catch (error) {
      console.error('Error updating call requests:', error);
      setError(t('error_updating_call_requests'));
      setSnackbar({
        open: true,
        message: t('error_updating_call_requests'),
        severity: 'error',
      });
      setFeedbackDialogOpen(false);
    }
  }, [
    selectedRequest,
    markAllAsHandled,
    otherUnhandledRequests,
    currentUsername,
    authToken,
    setCallRequests,
    feedback,
    t,
  ]);

  const handleSaveNote = useCallback(async () => {
    try {
      const updatedRequest = {
        comments: noteText,
      };
      const response = await axios.put(
        `/api/call_requests/${selectedRequest.id}`,
        updatedRequest,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? response.data : r))
      );
      setNoteDialogOpen(false);
      setNoteText('');
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('note_saved'), severity: 'success' });
    } catch (error) {
      console.error('Error updating call request:', error);
      setError(t('error_updating_call_request'));
      setSnackbar({
        open: true,
        message: t('error_updating_call_request'),
        severity: 'error',
      });
      setNoteDialogOpen(false);
    }
  }, [selectedRequest, noteText, authToken, setCallRequests, t]);

  // Handler for removing note
  const handleRemoveNote = useCallback(async () => {
    try {
      const updatedRequest = {
        comments: '',
      };
      const response = await axios.put(
        `/api/call_requests/${selectedRequest.id}`,
        updatedRequest,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? response.data : r))
      );
      setNoteDialogOpen(false);
      setNoteText('');
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('note_removed'), severity: 'success' });
    } catch (error) {
      console.error('Error removing note:', error);
      setError(t('error_removing_note'));
      setSnackbar({
        open: true,
        message: t('error_removing_note'),
        severity: 'error',
      });
      setNoteDialogOpen(false);
    }
  }, [selectedRequest, authToken, setCallRequests, t]);

  // Get status color for requests
  const getStatusColorFn = useCallback((request) => {
    if (request.status === 'handled') {
      return 'success.main'; // Green
    }
    const requestTime = dayjs(request.request_time);
    const now = dayjs();
    const diffMinutes = now.diff(requestTime, 'minute');
    if (diffMinutes <= 30) {
      return 'warning.main'; // Orange
    } else {
      return 'error.main'; // Red
    }
  }, []);

  // Handle Snackbar Close
  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  // Handle row click for reservation
  const handleRowClick = useCallback(
    async (request) => {
      if (request.reserved_by && request.reserved_by !== currentUsername) {
        // Näytä varmistusdialogi
        setSelectedRequest(request);
        setConfirmReleaseDialogOpen(true);
        return;
      }
      // Jatka normaalisti varauksen kanssa
      try {
        const response = await axios.post(`/api/call_requests/${request.id}/reserve`, null, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setCallRequests((prevRequests) =>
          prevRequests.map((r) => (r.id === request.id ? { ...r, ...response.data } : r))
        );
      } catch (error) {
        console.error('Error reserving/unreserving call request:', error);
        if (error.response && error.response.status === 409) {
          setSnackbar({
            open: true,
            message: t('call_request_reserved_by_other'),
            severity: 'warning',
          });
        } else {
          setError(t('error_updating_call_request'));
          setSnackbar({
            open: true,
            message: t('error_updating_call_request'),
            severity: 'error',
          });
        }
      }
    },
    [authToken, currentUsername, setCallRequests, t]
  );

  // Handle confirming release of reservation
  const handleConfirmRelease = useCallback(async () => {
    try {
      const response = await axios.post(`/api/call_requests/${selectedRequest.id}/release`, null, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCallRequests((prevRequests) =>
        prevRequests.map((r) => (r.id === selectedRequest.id ? { ...r, ...response.data } : r))
      );
      setConfirmReleaseDialogOpen(false);
      setSelectedRequest(null);
      setSnackbar({ open: true, message: t('reservation_released'), severity: 'success' });
    } catch (error) {
      console.error('Error releasing reservation:', error);
      setError(t('error_releasing_reservation'));
      setSnackbar({
        open: true,
        message: t('error_releasing_reservation'),
        severity: 'error',
      });
      setConfirmReleaseDialogOpen(false);
    }
  }, [selectedRequest, authToken, setCallRequests, t]);

  // ### Conditional Rendering ###
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress aria-label={t('loading')} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" sx={{ textAlign: 'center', marginTop: 4 }}>
        {error}
      </Typography>
    );
  }

  // ### JSX Rendering ###
  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: 'background.default',
        borderRadius: 2,
        boxShadow: 1,
        minHeight: '100vh',
      }}
    >
      {/* Add space for navigation menu */}
      <Box sx={{ ...((theme) => theme.mixins.toolbar) }} />

      {/* Filters */}
      <Filters
        filter={filter}
        handleFilterChange={handleFilterChange}
        handleClearFilter={handleClearFilter}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        availableLocations={availableLocations}
        statusFilter={statusFilter}
        handleStatusFilterChange={handleStatusFilterChange}
        unhandledCount={unhandledCount}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        adapterLocale={adapterLocale}
      />

      {/* Suspense for lazy-loaded components */}
      <Suspense fallback={<CircularProgress />}>
        {/* Call Requests Table */}
        <CallRequestsTable
          groupedCallRequests={groupedCallRequests}
          handleMarkAsHandled={handleMarkAsHandled}
          getStatusColor={getStatusColorFn}
          orderBy={orderBy}
          order={order}
          handleRequestSort={handleRequestSort}
          handleOpenNoteDialog={handleOpenNoteDialog}
          handleOpenSmsDialog={handleOpenSmsDialog}
          t={t}
          unreadSmsRequests={unreadSmsRequests}
          normalizePhoneNumber={normalizePhoneNumber}
          handleRowClick={handleRowClick}
          getRowBackgroundColor={getRowBackgroundColor}
          theme={theme}
        />

        {/* Feedback Dialog */}
        <FeedbackDialog
          open={feedbackDialogOpen}
          handleClose={() => setFeedbackDialogOpen(false)}
          feedback={feedback}
          setFeedback={setFeedback}
          handleSaveFeedback={handleSaveFeedback}
          markAllAsHandled={markAllAsHandled}
          setMarkAllAsHandled={setMarkAllAsHandled}
          otherUnhandledRequests={otherUnhandledRequests}
        />
      </Suspense>

      {/* Pagination */}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          showFirstButton
          showLastButton
          aria-label={t('pagination')}
        />
      </Box>

      {/* Note Dialog */}
      <NoteDialog
        open={noteDialogOpen}
        handleClose={() => setNoteDialogOpen(false)}
        selectedRequest={selectedRequest}
        noteText={noteText}
        setNoteText={setNoteText}
        handleSaveNote={handleSaveNote}
        handleRemoveNote={handleRemoveNote}
      />

      {/* Confirmation Dialog for Unmarking */}
      <ConfirmationDialog
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={confirmUnmarkHandled}
      />

      {/* SMS Conversation Dialog */}
      <SMSConversationDialog
        open={smsDialogOpen}
        handleClose={() => setSmsDialogOpen(false)}
        conversation={conversations[selectedRequest?.id] || []}
        sendMessage={sendMessage}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        t={t}
        loadingMessages={loadingMessages}
      />

      {/* Confirm Release Dialog */}
      <ConfirmReleaseDialog
        open={confirmReleaseDialogOpen}
        handleClose={() => setConfirmReleaseDialogOpen(false)}
        handleConfirm={handleConfirmRelease}
      />

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CallRequests;
