// src/timezones.js

const timezones = [
    'UTC',
    'Europe/Helsinki',
    'Europe/Stockholm',
    'Europe/London',
    'Europe/Berlin',
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'Asia/Tokyo',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Australia/Sydney',
    // Lisää tarvittaessa lisää aikavyöhykkeitä
  ];
  
  export default timezones;
  