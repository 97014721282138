// src/components/LanguageSelector.js
import React from 'react';
import { MenuItem, Select, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language || 'en'); // Varmista oletuskieli

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    if (i18n.changeLanguage) {
      i18n.changeLanguage(newLanguage); // Varmista, että changeLanguage on olemassa
    } else {
      console.error('changeLanguage function not available');
    }
  };

  return (
    <FormControl variant="standard" sx={{ minWidth: 120, color: 'white' }}>
      <Select
        value={language}
        onChange={handleChange}
        disableUnderline
        sx={{
          color: 'white', // Valkoinen teksti
          '.MuiSelect-icon': {
            color: 'white', // Valkoinen ikoni
          },
        }}
      >
        <MenuItem value="fi">Suomi</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
