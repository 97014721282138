// src/components/Login.js

import React, { useState } from 'react';
import { TextField, Button, Typography, Paper, Box, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// Poistetaan Login.css, koska tyylit on siirretty sx-propien kautta
// import './Login.css';

const Login = ({ setAuthToken }) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Tilakoukku lataustilaa varten

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    try {
      const response = await axios.post('/token', new URLSearchParams({
        username,
        password,
      }));
      const token = response.data.access_token;
      localStorage.setItem('token', token);
      setAuthToken(token);
    } catch (err) {
      setError(t('login_failed'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Korkeus täyttää koko ikkunan
        backgroundColor: 'background.default',
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 400,
          width: '100%',
          backgroundColor: 'background.paper',
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 3,
            textAlign: 'center',
            color: 'text.primary',
          }}
        >
          {t('login')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={t('username')}
            variant="outlined"
            fullWidth
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              marginBottom: 2,
            }}
          />
          <TextField
            label={t('password')}
            variant="outlined"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              marginBottom: 2,
            }}
          />
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            sx={{
              paddingY: 1.5,
            }}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : t('login')}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;
