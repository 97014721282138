// src/components/TrainingData.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  IconButton,
  TableContainer,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Edit as EditIcon, Delete as DeleteIcon, Sync as SyncIcon } from '@mui/icons-material';
import axios from 'axios';

const TrainingData = () => {
  const { t } = useTranslation();

  const [trainingData, setTrainingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filterText, setFilterText] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add');
  const [currentData, setCurrentData] = useState({ question: '', answer: '' });
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [syncSuccess, setSyncSuccess] = useState(null);

  useEffect(() => {
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axios.get('/api/locations');
      const locationsData = response.data;
      setLocations(locationsData);
      if (locationsData.length > 0) {
        setSelectedLocationId(locationsData[0].id);
        fetchTrainingData(locationsData[0].id);
      } else {
        setError('No locations found for the user.');
        setLoading(false);
      }
    } catch (err) {
      setError('Error fetching locations.');
      console.error(err);
      setLoading(false);
    }
  };

  const fetchTrainingData = async (locationId) => {
    try {
      setLoading(true);
      const trainingResponse = await axios.get(`/locations/${locationId}/training-data/`);
      setTrainingData(trainingResponse.data);
      setFilteredData(trainingResponse.data);
    } catch (err) {
      setError('Error fetching training data.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocationId(locationId);
    fetchTrainingData(locationId);
  };

  const handleFilterChange = (e) => {
    const text = e.target.value;
    setFilterText(text);
    const filtered = trainingData.filter((data) =>
      data.question.toLowerCase().includes(text.toLowerCase()) ||
      data.answer.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleOpenDialog = (mode, data) => {
    setDialogMode(mode);
    if (mode === 'edit') {
      setCurrentData({ ...data });
    } else {
      setCurrentData({ question: '', answer: '' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentData({ question: '', answer: '' });
  };

  const handleSaveData = async () => {
    try {
      if (!selectedLocationId) {
        setError('No location selected.');
        return;
      }
      if (dialogMode === 'add') {
        await axios.post(`/locations/${selectedLocationId}/training-data/`, currentData);
      } else if (dialogMode === 'edit') {
        await axios.put(`/training-data/${currentData.id}/`, currentData);
      }
      fetchTrainingData(selectedLocationId);
      handleCloseDialog();
    } catch (err) {
      setError('Error saving training data.');
      console.error(err);
    }
  };

  const handleDeleteData = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`/training-data/${id}/`);
        fetchTrainingData(selectedLocationId);
      } catch (err) {
        setError('Error deleting training data.');
        console.error(err);
      }
    }
  };

  const handleSyncData = async () => {
    if (!selectedLocationId) {
      setError('No location selected.');
      return;
    }
    setSyncInProgress(true);
    try {
      const response = await axios.post(`/api/locations/${selectedLocationId}/training-data/sync/`);

      if (response.status === 200) {
        setSyncSuccess(true);
        fetchTrainingData(selectedLocationId); // Päivitä data synkronoinnin jälkeen
      } else {
        setSyncSuccess(false);
      }
    } catch (err) {
      setSyncSuccess(false);
      console.error(err);
    } finally {
      setSyncInProgress(false);
    }
  };

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: 'background.default',
        borderRadius: 2,
        boxShadow: 1,
        minHeight: '100vh',
      }}
    >
      {/* Lisää tilaa navigaatiomenuun */}
      <Box sx={(theme) => theme.mixins.toolbar} />

      {/* Filters */}
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Toimipisteen valinta */}
          <Grid item xs={12} md={4}>
            {locations.length > 0 && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="location-select-label">{t('select_location')}</InputLabel>
                <Select
                  labelId="location-select-label"
                  value={selectedLocationId}
                  onChange={handleLocationChange}
                  label={t('select_location')}
                >
                  {locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          {/* Hakukenttä */}
          <Grid item xs={12} md={4}>
            <TextField
              label={t('search')}
              variant="outlined"
              value={filterText}
              onChange={handleFilterChange}
              fullWidth
              InputProps={{
                endAdornment: filterText && (
                  <IconButton onClick={() => setFilterText('')}>
                    <DeleteIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          {/* Painikkeet */}
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenDialog('add')}
              sx={{ mr: 2 }}
            >
              {t('add_new')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSyncData}
              disabled={syncInProgress}
              startIcon={<SyncIcon />}
            >
              {syncInProgress ? t('syncing') : t('sync_data')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Konditionaalinen Renderöinti */}
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            backgroundColor: 'background.default',
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography
          variant="h6"
          color="error"
          sx={{ textAlign: 'center', marginTop: 4 }}
        >
          {error}
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('question')}</TableCell>
                <TableCell>{t('answer')}</TableCell>
                <TableCell align="right">{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((data) => (
                <TableRow key={data.id} hover>
                  <TableCell>{data.question}</TableCell>
                  <TableCell>{data.answer}</TableCell>
                  <TableCell align="right">
                    <Tooltip title={t('edit')}>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenDialog('edit', data)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('delete')}>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteData(data.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {filteredData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    {t('no_data')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Dialogi lisäystä ja muokkausta varten */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {dialogMode === 'add' ? t('add_training_data') : t('edit_training_data')}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={t('question')}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={currentData.question}
            onChange={(e) => setCurrentData({ ...currentData, question: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            label={t('answer')}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={currentData.answer}
            onChange={(e) => setCurrentData({ ...currentData, answer: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('cancel')}</Button>
          <Button
            onClick={handleSaveData}
            color="primary"
            variant="contained"
            disabled={!currentData.question.trim() || !currentData.answer.trim()}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Synkronoinnin tuloksen näyttäminen */}
      <Snackbar
        open={syncSuccess !== null}
        autoHideDuration={6000}
        onClose={() => setSyncSuccess(null)}
      >
        <Alert
          onClose={() => setSyncSuccess(null)}
          severity={syncSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {syncSuccess ? t('sync_success') : t('sync_error')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TrainingData;
