// src/WebSocketContext.js

import React, { createContext, useEffect, useRef } from 'react';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const ws = useRef(null);
  const subscribers = useRef({});
  const isConnecting = useRef(false);

  useEffect(() => {
    if (isConnecting.current || ws.current) {
      console.log('WebSocket is already connecting or connected.');
      return; // Vältetään useita yhteyksiä
    }

    isConnecting.current = true;

    const connect = () => {
      console.log('Establishing WebSocket connection...');
      ws.current = new WebSocket('wss://voiceai.hidoro.fi/ws');

      ws.current.onopen = () => {
        console.log('WebSocket connected.');
      };

      ws.current.onmessage = (event) => {
        console.log("Received WebSocket message:", event.data);
        try {
          const message = JSON.parse(event.data);
          console.log("Parsed WebSocket message:", message);

          const { type, data } = message;
          if (subscribers.current[type]) {
            subscribers.current[type].forEach(callback => callback(data));
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      ws.current.onclose = (event) => {
        console.log(`WebSocket disconnected: ${event.code} ${event.reason}. Reconnecting in 5 seconds...`);
        ws.current = null;
        setTimeout(connect, 5000);
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.current.close();
      };
    };

    connect();

    return () => {
      if (ws.current) {
        console.log('Closing WebSocket connection.');
        ws.current.close();
      }
    };
  }, []);

  const subscribe = (eventName, callback) => {
    if (!subscribers.current[eventName]) {
      subscribers.current[eventName] = [];
    }
    subscribers.current[eventName].push(callback);
    return () => {
      subscribers.current[eventName] = subscribers.current[eventName].filter(cb => cb !== callback);
    };
  };

  return (
    <WebSocketContext.Provider value={{ subscribe }}>
      {children}
    </WebSocketContext.Provider>
  );
};
