// src/App.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Container,
  CssBaseline,
  CircularProgress, // Lisätty latausindikaattori
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { getTheme, setCSSVariables, lightPalette, darkPalette } from './theme';
import {
  HashRouter as Router, // Muutettu BrowserRouter -> HashRouter
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import CallList from './components/CallList';
import CallRequests from './components/CallRequests';
import ControlPanel from './components/ControlPanel';
import TrainingData from './components/TrainingData';
import Report from './components/Report';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';
import Register from './components/Register';
import './App.css';
import { WebSocketProvider } from './WebSocketContext';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CallRequestProvider } from './contexts/CallRequestContext';
import Navbar from './components/Navbar';
import AppContext from './contexts/AppContext';

// Luo ThemeContext
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const App = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [userName, setUserName] = useState(''); // Lisää userName
  const [mode, setMode] = useState(localStorage.getItem('themeMode') || 'light');
  const [loading, setLoading] = useState(true); // Lisätty lataustila

  // Luo teemassa vaihto funktio
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('themeMode', newMode);
          setCSSVariables(newMode === 'light' ? lightPalette : darkPalette); // Päivitä CSS-muuttujat
          return newMode;
        });
      },
    }),
    []
  );

  // Luo nykyinen teema
  const theme = useMemo(() => getTheme(mode), [mode]);

  // Asetetaan axios oletusotsikot, kun authToken päivittyy
  useEffect(() => {
    if (authToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [authToken]);

  // Haetaan nykyisen käyttäjän tiedot
  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (authToken) {
        try {
          const response = await axios.get('/users/me/'); // Varmista, että polku on oikein
          console.log('Current user data:', response.data);
          setIsAdmin(response.data.is_admin);
          setIsSysAdmin(response.data.is_sys_admin);
          setUserName(response.data.username); // Oletetaan, että response.data sisältää username
        } catch (error) {
          console.error('Virhe haettaessa käyttäjätietoja:', error);
          setIsAdmin(false);
          setIsSysAdmin(false);
          setUserName('');
          // Token on epäkelpo, poista se
          localStorage.removeItem('token');
          setAuthToken(null);
        }
      } else {
        setUserName('');
      }
      setLoading(false); // Lopeta lataus
    };

    fetchCurrentUser();
  }, [authToken]);

  // Käsitellään uloskirjautuminen
  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setIsAdmin(false);
    setIsSysAdmin(false);
    setUserName('');
  };

  // Lisää teeman CSS-muuttujien asettaminen
  useEffect(() => {
    setCSSVariables(mode === 'light' ? lightPalette : darkPalette);
  }, [mode]);

  if (loading) {
    // Näytä latausindikaattori, kun data ladataan
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <AppContext.Provider value={{ isAdmin, isSysAdmin, authToken, setAuthToken, userName, setUserName }}>
        <ThemeProvider theme={theme}>
          <CssBaseline /> {/* Käytetään */}
          <WebSocketProvider>
            <CallRequestProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.default,
                      minHeight: '100vh',
                      width: '100%', // Varmistaa, että Box käyttää koko leveyttä
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* Käytetään uutta Navbar-komponenttia */}
                    <Navbar
                      handleLogout={handleLogout}
                      mode={mode}
                      toggleColorMode={colorMode.toggleColorMode}
                    />

                    <Box
                      sx={{
                        flexGrow: 1,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Container
                        maxWidth={false}
                        disableGutters
                        sx={{ padding: '32px', width: '100%' }} // Poistetaan maxWidth rajoitukset
                      >
                        <Routes>
                          {!authToken ? (
                            <>
                              <Route path="/login" element={<Login setAuthToken={setAuthToken} />} />
                              <Route path="/register" element={<Register setAuthToken={setAuthToken} />} />
                              <Route path="*" element={<Navigate to="/login" replace />} />
                            </>
                          ) : (
                            <>
                              <Route path="/" element={<CallRequests />} />
                              <Route path="/reports" element={<CallList />} />
                              <Route path="/call_requests" element={<CallRequests />} />
                              <Route path="/statistics" element={<Report />} />
                              {(isAdmin || isSysAdmin) && (
                                <Route path="/training-data" element={<TrainingData />} />
                              )}
                              {isSysAdmin && (
                                <>
                                  <Route path="/controlpanel" element={<ControlPanel />} />
                                  <Route path="/admin" element={<AdminPanel />} />
                                </>
                              )}
                              {isAdmin && !isSysAdmin && (
                                <Route path="/admin" element={<AdminPanel />} />
                              )}
                              <Route path="*" element={<Navigate to="/" replace />} />
                            </>
                          )}
                        </Routes>
                      </Container>
                    </Box>
                  </Box>
                </Router>
              </LocalizationProvider>
            </CallRequestProvider>
          </WebSocketProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </ColorModeContext.Provider>
  );
};

export default App;
