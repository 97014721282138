// src/theme.js

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

// 1. Määrittele värit ensin

export const lightPalette = {
  mode: 'light',
  primary: {
    main: '#6CA0DC', // Pehmeämpi sininen
    light: '#8CB4F0',
    dark: '#4B7AC3',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#70C1B3', // Lempeä vihreä
    light: '#90D5C9',
    dark: '#57989A',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#4CAF50', // Pehmeä vihreä
    light: '#6FBF73',
    dark: '#3B8E47',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#E57373', // Pehmeä punainen
    light: '#EF9A9A',
    dark: '#D32F2F',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#64B5F6', // Pehmeä sininen
    light: '#90CAF9',
    dark: '#42A5F5',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FFB74D', // Pehmeä oranssi
    light: '#FFCC80',
    dark: '#FB8C00',
    contrastText: '#212B36',
  },
  neutral: {
    main: '#B0BEC5', // Pehmeä harmaa
    light: '#CFD8DC',
    dark: '#90A4AE',
    contrastText: '#212B36',
  },
  background: {
    default: '#F5F5F5', // Vaalea harmaa (ei täysin valkoinen)
    paper: '#FFFFFF', // Valkoinen paperi
  },
  text: {
    primary: '#212B36', // Tumma teksti
    secondary: '#607D8B', // Keskiharmaa
    disabled: '#B0BEC5',
  },
  divider: '#E0E0E0', // Vaalea divider
  action: {
    hover: 'rgba(108, 160, 220, 0.08)', // Lisää kontrastia hover-tilaan
    selected: 'rgba(108, 160, 220, 0.16)', // Lisää kontrastia selected-tilaan
    disabled: 'rgba(0, 0, 0, 0.12)', // Lisätty disabled-tila
    focus: 'rgba(108, 160, 220, 0.24)', // Lisätty focus-tila
    active: 'rgba(108, 160, 220, 0.32)', // Lisätty aktiivinen tila
  },
};

export const darkPalette = {
  mode: 'dark',
  primary: {
    main: '#5C6BC0', // Pehmeämpi tumma sininen
    light: '#7986CB',
    dark: '#3949AB',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#26A69A', // Pehmeä tumma vihreä
    light: '#4DB6AC',
    dark: '#00766C',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#81C784', // Pehmeä vihreä
    light: '#A5D6A7',
    dark: '#66BB6A',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#E57373', // Pehmeä punainen
    light: '#EF9A9A',
    dark: '#D32F2F',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#64B5F6', // Pehmeä sininen
    light: '#90CAF9',
    dark: '#42A5F5',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FFB74D', // Pehmeä oranssi
    light: '#FFCC80',
    dark: '#FB8C00',
    contrastText: '#212B36',
  },
  neutral: {
    main: '#90A4AE', // Pehmeä harmaa
    light: '#B0BEC5',
    dark: '#78909C',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#121212', // Tumma tausta
    paper: '#1E1E1E', // Tumma paperi
  },
  text: {
    primary: '#FFFFFF', // Vaalea teksti
    secondary: '#B0BEC5', // Harmaa teksti
    disabled: '#78909C',
  },
  divider: '#424242', // Tumma divider
  action: {
    hover: 'rgba(108, 160, 220, 0.16)', // Lisää kontrastia hover-tilaan
    selected: 'rgba(108, 160, 220, 0.32)', // Lisää kontrastia selected-tilaan
    disabled: 'rgba(255, 255, 255, 0.12)', // Lisätty disabled-tila
    focus: 'rgba(108, 160, 220, 0.24)', // Lisätty focus-tila
    active: 'rgba(108, 160, 220, 0.32)', // Lisätty aktiivinen tila
  },
};

// 2. Luo perus teemat ilman komponenttien ylikirjoituksia

const baseLightTheme = createTheme({
  palette: lightPalette,
  typography: {
    fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
      '@media (min-width:600px)': {
        fontSize: '2.75rem',
      },
      '@media (min-width:960px)': {
        fontSize: '3.25rem',
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 10, // Hieman suurempi reunus pyöristys
  },
  spacing: 8,
});

const baseDarkTheme = createTheme({
  palette: darkPalette,
  typography: {
    fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
      '@media (min-width:600px)': {
        fontSize: '2.75rem',
      },
      '@media (min-width:960px)': {
        fontSize: '3.25rem',
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 10, // Hieman suurempi reunus pyöristys
  },
  spacing: 8,
});

// 3. Laajenna teema komponenttien ylikirjoituksilla

const componentsOverrides = (theme) => ({
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.default}`,
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          backgroundColor: theme.palette.background.default,
          width: '12px',
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.primary.main,
          minHeight: 24,
          border: `3px solid ${theme.palette.background.default}`,
        },
        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
          backgroundColor: theme.palette.background.default,
        },
      },
      body: {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius * 1.6, // Suurempi pyöristys
        transition: theme.transitions.create(['transform', 'box-shadow'], {
          duration: theme.transitions.duration.standard,
        }),
        boxShadow: theme.shadows[3], // Syvempi varjo
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.shadows[6],
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.25, 3), // 10px 24px
        transition: theme.transitions.create(['background-color', 'transform', 'box-shadow'], {
          duration: theme.transitions.duration.short,
        }),
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: theme.shadows[2],
        },
      },
      containedPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      containedSecondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
      outlinedPrimary: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          borderColor: theme.palette.primary.dark,
        },
      },
      outlinedSecondary: {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          borderColor: theme.palette.secondary.dark,
        },
      },
      textPrimary: {
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
      textSecondary: {
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        color: theme.palette.text.primary,
      },
      h2: {
        color: theme.palette.text.primary,
      },
      h3: {
        color: theme.palette.text.primary,
      },
      h4: {
        color: theme.palette.text.primary,
      },
      h5: {
        color: theme.palette.text.primary,
      },
      h6: {
        color: theme.palette.text.primary,
      },
      subtitle1: {
        color: theme.palette.text.secondary,
      },
      subtitle2: {
        color: theme.palette.text.secondary,
      },
      body1: {
        color: theme.palette.text.primary,
      },
      body2: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: theme.palette.secondary.main,
        height: '4px',
        borderRadius: '2px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        '&.Mui-selected': {
          color: theme.palette.secondary.main,
          fontWeight: 700,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: theme.palette.primary.main,
      },
      select: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.dark,
          borderWidth: '2px',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: '0.9rem',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
        padding: theme.spacing(1, 1.5),
      },
      arrow: {
        color: theme.palette.background.paper,
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      badge: {
        borderRadius: theme.shape.borderRadius,
        padding: '0 6px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: theme.shadows[1],
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.secondary,
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 52,
        height: 28,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        color: theme.palette.neutral.main,
        padding: 2,
        '&.Mui-checked': {
          transform: 'translateX(24px)',
          color: theme.palette.primary.contrastText,
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
            border: 0,
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 14,
        backgroundColor: theme.palette.neutral.light,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
        height: 6,
      },
      thumb: {
        height: 20,
        width: 20,
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.primary.main}`,
        marginTop: -7,
        marginLeft: -10,
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: 'inherit',
        },
      },
      track: {
        height: 6,
        borderRadius: 3,
      },
      rail: {
        height: 6,
        borderRadius: 3,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
        '&.Mui-selected': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
        backgroundColor: 'inherit',
      },
      head: {
        backgroundColor: theme.palette.background.paper,
        fontWeight: 700,
        color: theme.palette.text.primary,
        fontSize: '1rem',
      },
      body: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        // backgroundColor: theme.palette.background.paper, // Päivitetty tämä rivi
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected,
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: theme.shape.borderRadius * 1.2,
        boxShadow: theme.shadows[3],
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 6,
        borderRadius: 3,
        backgroundColor: theme.palette.neutral.light,
      },
      bar: {
        borderRadius: 3,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        color: theme.palette.text.secondary,
      },
      separator: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
        '&:hover': {
          color: theme.palette.primary.dark,
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.neutral.light,
        color: theme.palette.text.primary,
      },
      outlined: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
      filledPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      filledSecondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
    },
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
      ul: {
        '& .MuiPaginationItem-root': {
          borderRadius: theme.shape.borderRadius,
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
          },
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius * 1.2,
        border: `1px solid ${theme.palette.divider}`,
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 'auto',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius * 1.2,
        minHeight: 56,
        '&.Mui-expanded': {
          minHeight: 56,
        },
      },
      content: {
        margin: '12px 0',
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: theme.palette.background.default,
        borderRight: `1px solid ${theme.palette.divider}`,
        width: 260,
        transition: theme.transitions.create('width', {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.5, 2),
        fontWeight: 500,
        boxShadow: theme.shadows[2],
      },
      standardSuccess: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
      },
      standardError: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
      },
      standardWarning: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.contrastText,
      },
      standardInfo: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.contrastText,
      },
    },
  },
  // Lisätään Dialog-komponenttien ylikirjoitukset
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        fontWeight: 700,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
  // Lisätään uusia komponenttiylikirjoituksia
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: 500,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        '&.Mui-active': {
          color: theme.palette.primary.main,
        },
        '&.Mui-completed': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

// 4. Luo valmiit teemat valitun tilan mukaan

export const getTheme = (mode) => {
  const baseTheme = mode === 'light' ? baseLightTheme : baseDarkTheme;

  let theme = createTheme(baseTheme, {
    components: componentsOverrides(baseTheme),
  });

  // Lisää responsiiviset fonttikoot
  theme = responsiveFontSizes(theme);

  return theme;
};

// 5. Aseta CSS-muuttujat teemasta

export const setCSSVariables = (palette) => {
  const root = document.documentElement;
  Object.keys(palette).forEach((key) => {
    if (typeof palette[key] === 'object') {
      Object.keys(palette[key]).forEach((subKey) => {
        root.style.setProperty(`--${key}-${subKey}`, palette[key][subKey]);
      });
    }
  });

  // Lisää muita tarvittavia CSS-muuttujia
  root.style.setProperty('--background-default', palette.background.default);
  root.style.setProperty('--background-paper', palette.background.paper);
  root.style.setProperty('--text-primary', palette.text.primary);
  root.style.setProperty('--text-secondary', palette.text.secondary);
  root.style.setProperty('--text-disabled', palette.text.disabled);
  root.style.setProperty('--divider-color', palette.divider);

  // Lisää komponenttikohtaiset CSS-muuttujat
  root.style.setProperty('--card-shadow', '0 4px 20px rgba(0, 0, 0, 0.1)');
  root.style.setProperty('--card-hover-shadow', '0 8px 30px rgba(0, 0, 0, 0.15)');
  root.style.setProperty('--tooltip-bg', palette.background.paper);
  root.style.setProperty('--tooltip-color', palette.text.primary);
  root.style.setProperty('--button-primary-bg', palette.primary.main);
  root.style.setProperty('--button-primary-hover-bg', palette.primary.dark);
  root.style.setProperty('--button-secondary-bg', palette.secondary.main);
  root.style.setProperty('--button-secondary-hover-bg', palette.secondary.dark);

  // Lisää lisämuuttujia tarpeen mukaan
  root.style.setProperty('--accordion-bg', palette.background.paper);
  root.style.setProperty('--drawer-bg', palette.background.default);
  root.style.setProperty('--snackbar-bg', palette.primary.main);
  root.style.setProperty('--snackbar-text', palette.primary.contrastText);
  root.style.setProperty('--fab-bg', palette.secondary.main);
  root.style.setProperty('--fab-hover-bg', palette.secondary.dark);
};

