// src/contexts/CallRequestContext.js

import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { WebSocketContext } from '../WebSocketContext';

export const CallRequestContext = createContext();

export const CallRequestProvider = ({ children }) => {
  const { subscribe } = useContext(WebSocketContext);
  const [callRequests, setCallRequests] = useState([]);
  const [unhandledCount, setUnhandledCount] = useState(0);
  const [unreadSmsRequests, setUnreadSmsRequests] = useState(new Set());
  const authToken = localStorage.getItem('token');
  const originalTitleRef = useRef(document.title);

  // Helper-funktio puhelinnumeroiden normalisointiin
  const normalizePhoneNumber = (number) => {
    return number.replace(/\D/g, '');
  };

  // Fetch initial call requests
  useEffect(() => {
    const fetchCallRequests = async () => {
      try {
        const response = await axios.get('/api/call_requests', {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setCallRequests(response.data);
      } catch (error) {
        console.error('Error fetching call requests:', error);
      }
    };
    fetchCallRequests();
  }, [authToken]);

  // Päivitä unhandledCount ja dokumentin title
  useEffect(() => {
    const count = callRequests.filter(
      (request) => request.status === 'pending' || request.status === 'unhandled'
    ).length;
    setUnhandledCount(count);

    if (count > 0) {
      document.title = `${originalTitleRef.current} (${count})`;
    } else {
      document.title = originalTitleRef.current;
    }
  }, [callRequests]);

  // WebSocket-käsittely
  useEffect(() => {
    if (subscribe) {
      const handleNewCallRequest = (data) => {
        setCallRequests((prevRequests) => [data, ...prevRequests]);
      };

      const handleUpdateCallRequest = (data) => {
        setCallRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.id === data.id ? { ...request, ...data } : request
          )
        );
      };

      const handleDeleteCallRequest = (data) => {
        setCallRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== data.id)
        );
      };

      // Handleri uusille SMS-viesteille
      const handleNewSmsMessage = (data) => {
        const requestId = data.request_id;
        if (requestId) {
          setUnreadSmsRequests((prevSet) => new Set(prevSet).add(requestId));
        } else {
          console.warn('Received SMS without request_id');
        }
      };

      const unsubscribeNew = subscribe('new_call_request', handleNewCallRequest);
      const unsubscribeUpdate = subscribe('update_call_request', handleUpdateCallRequest);
      const unsubscribeDelete = subscribe('delete_call_request', handleDeleteCallRequest);
      const unsubscribeNewSms = subscribe('new_sms_message', handleNewSmsMessage);

      return () => {
        unsubscribeNew();
        unsubscribeUpdate();
        unsubscribeDelete();
        unsubscribeNewSms();
      };
    }
  }, [subscribe]);

  return (
    <CallRequestContext.Provider
      value={{
        callRequests,
        setCallRequests,
        unhandledCount,
        unreadSmsRequests,
        setUnreadSmsRequests,
        normalizePhoneNumber,
        subscribe,
      }}
    >
      {children}
    </CallRequestContext.Provider>
  );
};
