// src/components/Register.js

import React, { useState } from 'react';
import { TextField, Button, Typography, Paper, Box } from '@mui/material';
import axios from 'axios';
import './Register.css';

const Register = ({ setAuthToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Lähetetään pyyntö sysadminin luomiseksi
      await axios.post('/users/', {
        username,
        password,
        is_sys_admin: true, // Asetetaan is_sys_admin todeksi
        location_ids: [],
      });

      // Kirjaudutaan sisään automaattisesti onnistuneen rekisteröinnin jälkeen
      const response = await axios.post('/token', new URLSearchParams({
        username,
        password,
      }));
      const token = response.data.access_token;
      localStorage.setItem('token', token);
      setAuthToken(token);

      setSuccess('Sysadmin-käyttäjä luotu onnistuneesti.');
      setError('');
    } catch (err) {
      setError('Sysadmin-käyttäjän luominen epäonnistui.');
      setSuccess('');
    }
  };

  return (
    <Box className="register-container">
      <Paper elevation={3} className="register-paper">
        <Typography variant="h5" className="register-title">
          Luo Sysadmin
        </Typography>
        <form onSubmit={handleRegister} className="register-form">
          <TextField
            label="Käyttäjätunnus"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="register-input"
          />
          <TextField
            label="Salasana"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="register-input"
          />
          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="primary">{success}</Typography>}
          <Button type="submit" variant="contained" color="primary" className="register-button">
            Luo Sysadmin
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default Register;
