// src/components/AdminPanel.js

import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import {
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// Laiskasti ladattavat komponentit
const SystemMessageSection = lazy(() => import('./AdminPanel/SystemMessageSection'));
const AutomaticSmsSection = lazy(() => import('./AdminPanel/AutomaticSmsSection'));
const ContactInfoSmsSection = lazy(() => import('./AdminPanel/ContactInfoSmsSection'));
const GreetingMessageSection = lazy(() => import('./AdminPanel/GreetingMessageSection'));
const CallTransferSettingsSection = lazy(() => import('./AdminPanel/CallTransferSettingsSection'));
const SmsTemplatesSection = lazy(() => import('./AdminPanel/SmsTemplatesSection'));

// Asetetaan Authorization-otsikko kaikille Axios-pyynnöille
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const AdminPanel = () => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch Locations
  const fetchLocations = useCallback(async () => {
    try {
      const response = await axios.get('/api/locations');
      setLocations(response.data);
      if (response.data.length > 0) {
        setSelectedLocationId(response.data[0].id);
      }
    } catch (err) {
      setError(t('error_fetching_locations'));
      console.error(err);
    } finally {
      setLoadingLocations(false);
    }
  }, [t]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const handleLocationChange = useCallback((event) => {
    const locationId = event.target.value;
    setSelectedLocationId(locationId);
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSuccessMessage('');
    setError('');
  }, []);

  if (loadingLocations) {
    return (
      <Paper sx={{ padding: 4 }}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Paper sx={{ padding: 4 }}>
      {/* Location Selection */}
      <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
        <InputLabel id="location-select-label">{t('select_location')}</InputLabel>
        <Select
          labelId="location-select-label"
          value={selectedLocationId}
          onChange={handleLocationChange}
          label={t('select_location')}
        >
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* System Message Section */}
      <Suspense fallback={<CircularProgress />}>
        <SystemMessageSection
          selectedLocationId={selectedLocationId}
          t={t}
          setError={setError}
          setSuccessMessage={setSuccessMessage}
        />
      </Suspense>

      {/* Automatic SMS Section */}
      <Suspense fallback={<CircularProgress />}>
        <AutomaticSmsSection
          selectedLocationId={selectedLocationId}
          t={t}
          setError={setError}
          setSuccessMessage={setSuccessMessage}
        />
      </Suspense>

      {/* Contact Info SMS Section */}
      <Suspense fallback={<CircularProgress />}>
        <ContactInfoSmsSection
          selectedLocationId={selectedLocationId}
          t={t}
          setError={setError}
          setSuccessMessage={setSuccessMessage}
        />
      </Suspense>

      {/* Greeting Message Section */}
      <Suspense fallback={<CircularProgress />}>
        <GreetingMessageSection
          selectedLocationId={selectedLocationId}
          t={t}
          setError={setError}
          setSuccessMessage={setSuccessMessage}
        />
      </Suspense>

      {/* Call Transfer Settings Section */}
      <Suspense fallback={<CircularProgress />}>
        <CallTransferSettingsSection
          selectedLocationId={selectedLocationId}
          t={t}
          setError={setError}
          setSuccessMessage={setSuccessMessage}
        />
      </Suspense>

      {/* SMS Templates Section */}
      <Suspense fallback={<CircularProgress />}>
        <SmsTemplatesSection
          selectedLocationId={selectedLocationId}
          t={t}
          setError={setError}
          setSuccessMessage={setSuccessMessage}
        />
      </Suspense>

      {/* Snackbar onnistumisviestille */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar virheilmoitukselle */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  );
};

export default AdminPanel;
