// src/components/CallList.js

import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
//  Paper,
  Typography,
  CircularProgress,
  TextField,
  Grid,
  Box,
  Pagination,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { WebSocketContext } from '../WebSocketContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/fi';
import 'dayjs/locale/en';
import { CallMade, CallReceived } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles'; // Lisätty tämä

dayjs.extend(utc);
dayjs.extend(timezone);

// ### Alikomponentit ###

// Filters Component
const Filters = React.memo(({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  locations,
  selectedLocation,
  handleLocationChange,
  downloadExcel,
  handleRefresh,
  loading,
  t,
  adapterLocale
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
      <Grid container spacing={2} sx={{ marginBottom: 4 }}>
        {/* Start Date */}
        <Grid item xs={12} sm={3}>
          <DatePicker
            label={t('start_date')}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            maxDate={endDate}
            aria-label={t('start_date')}
          />
        </Grid>

        {/* End Date */}
        <Grid item xs={12} sm={3}>
          <DatePicker
            label={t('end_date')}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            minDate={startDate}
            aria-label={t('end_date')}
          />
        </Grid>

        {/* Location Selector */}
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="location-select-label">{t('select_location')}</InputLabel>
            <Select
              labelId="location-select-label"
              id="location-select"
              value={selectedLocation || ''}
              label={t('select_location')}
              onChange={handleLocationChange}
              aria-label={t('select_location')}
            >
              {locations.length > 1 && (
                <MenuItem value="">
                  <em>{t('all_locations')}</em>
                </MenuItem>
              )}
              {locations.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Download Excel and Refresh Buttons */}
        <Grid item xs={12} sm={3} container spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={downloadExcel}
              fullWidth
              disabled={loading}
              aria-label={t('download_excel')}
            >
              {t('download_excel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRefresh}
              fullWidth
              disabled={loading}
              aria-label={t('refresh')}
            >
              {loading ? <CircularProgress size={24} /> : t('refresh')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
});

// CallListTable Component
const CallListTable = React.memo(({
  groupedCalls,
  formatDurationToString,
  locationTimezones,
  t
}) => {
  const theme = useTheme(); // Lisätty tämä

  // Funktio rivin taustavärin määrittämiseen
  const getRowBackgroundColor = (call) => {
    // Määritä logiikka haluamallasi tavalla
    if (call.forwarded_to_number && call.forwarded_to_number !== 'N/A') {
      return theme.palette.action.selected; // Siirretty puhelu
    } else {
      return 'transparent'; // Tavallinen puhelu
    }
  };

  return (
    <TableContainer sx={{ maxHeight: 600 }}>
      <Table stickyHeader aria-label={t('call_list_table')}>
        <TableHead>
          <TableRow>
            {/* Uudet otsikot */}
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('status')}
            </TableCell>
            {/* Lisää muut otsikkosolut samalla tavalla */}
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('caller_number')}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('called_number')}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('forwarded_to')}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('call_started')}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('call_ended')}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('forwarded_call_duration')}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('call_duration')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedCalls)
            .sort((a, b) => dayjs(b).unix() - dayjs(a).unix())
            .map((date) => (
              <React.Fragment key={date}>
                {/* Date Header */}
                <TableRow>
                  <TableCell colSpan={8} align="left" sx={{ backgroundColor: 'inherit' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                      {dayjs(date).format('MMMM D, YYYY')}
                    </Typography>
                  </TableCell>
                </TableRow>
                {/* Calls for the Date */}
                {groupedCalls[date].map((call) => {
                  // Määritetään, onko puhelu siirretty
                  const isForwarded =
                    call.forwarded_to_number &&
                    call.forwarded_to_number !== 'N/A' &&
                    call.forwarded_call_duration &&
                    call.forwarded_call_duration !== 'N/A';

                  // Hae puhelun aikavyöhyke
                  const callTimezone = locationTimezones[call.location_id] || 'UTC';

                  // Hae rivin taustaväri
                  const rowBackgroundColor = getRowBackgroundColor(call);

                  return (
                    <TableRow
                      key={call.id}
                      hover
                      tabIndex={-1}
                      role="checkbox"
                      sx={{
                        backgroundColor: rowBackgroundColor,
                        '&:hover': {
                          backgroundColor: theme.palette.action.hover,
                        },
                      }}
                    >
                      <TableCell align="center">
                        {isForwarded ? (
                          <Tooltip title={t('forwarded_call')}>
                            <CallMade color="primary" aria-label={t('forwarded_call')} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={t('regular_call')}>
                            <CallReceived color="action" aria-label={t('regular_call')} />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">{call.caller_number}</TableCell>
                      <TableCell align="center">{call.called_number}</TableCell>
                      <TableCell align="center">
                        {call.forwarded_to_number ? call.forwarded_to_number : 'N/A'}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs.utc(call.start_time).tz(callTimezone).format('YYYY-MM-DD HH:mm:ss')}
                      </TableCell>
                      <TableCell align="center">
                        {call.end_time
                          ? dayjs.utc(call.end_time).tz(callTimezone).format('YYYY-MM-DD HH:mm:ss')
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="center">
                        {call.forwarded_call_duration
                          ? formatDurationToString(call.forwarded_call_duration)
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="center">{formatDurationToString(call.duration)}</TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          {Object.keys(groupedCalls).length === 0 && (
            <TableRow>
              <TableCell colSpan={8} align="center">
                {t('no_data')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

// Snackbar Component
const ErrorSnackbar = React.memo(({ open, message, severity, handleClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
});

// ### Main Component ###
const CallList = () => {
  const { t, i18n } = useTranslation();
  const { subscribe } = useContext(WebSocketContext);
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for date range filtering
  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  // State for location filtering
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;

  // State for timezone mapping
  const [locationTimezones, setLocationTimezones] = useState({});

  const authToken = localStorage.getItem('token');

  // Snackbar state
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Fetch locations from API
  const fetchLocations = useCallback(async () => {
    try {
      const response = await axios.get('/api/locations', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setLocations(response.data);

      // Luo mappi location_id -> timezone
      const timezoneMap = {};
      response.data.forEach((location) => {
        timezoneMap[location.id] = location.timezone || 'UTC';
      });
      setLocationTimezones(timezoneMap);

      // Jos vain yksi sijainti, valitaan se automaattisesti
      if (response.data.length === 1) {
        setSelectedLocation(response.data[0].id);
      }
    } catch (error) {
      console.error('Virhe toimipisteiden haussa:', error);
      setError(t('error_fetching_locations'));
      setLoading(false);
      setSnackbar({ open: true, message: t('error_fetching_locations'), severity: 'error' });
    }
  }, [authToken, t]);

  // Fetch calls from API
  const fetchCalls = useCallback(async () => {
    try {
      const response = await axios.get('/api/calls', {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setCalls(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Virhe haettaessa puheluita:', error);
      setError(t('error_fetching_calls'));
      setLoading(false);
      setSnackbar({ open: true, message: t('error_fetching_calls'), severity: 'error' });
    }
  }, [authToken, t]);

  useEffect(() => {
    fetchCalls();
    fetchLocations();
  }, [fetchCalls, fetchLocations]);

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (subscribe) {
      const unsubscribe = subscribe('update_calls', (data) => {
        setCalls((prevCalls) => [data, ...prevCalls]);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [subscribe]);

  // Function to format ISO 8601 duration to a human-readable string
  const formatDurationToString = useCallback((duration) => {
    if (!duration || typeof duration !== 'string') return 'N/A';
    if (!duration.startsWith('PT')) return 'N/A';

    const durationRegex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?/;
    const matches = duration.match(durationRegex);

    if (!matches) return 'N/A';

    const hours = parseInt(matches[1] || 0, 10);
    const minutes = parseInt(matches[2] || 0, 10);
    const seconds = Math.floor(parseFloat(matches[3] || 0));

    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds}s`);

    return parts.join(' ');
  }, []);

  // Filter calls by date range and location, and sort them descending
  const filteredCalls = useMemo(() => {
    return calls
      .filter((call) => {
        const callDate = dayjs(call.start_time);
        if (startDate && callDate.isBefore(dayjs(startDate).startOf('day'))) return false;
        if (endDate && callDate.isAfter(dayjs(endDate).endOf('day'))) return false;
        if (selectedLocation) {
          return call.location_id === selectedLocation;
        }
        return true; // Näytä kaikki puhelut
      })
      .sort((a, b) => dayjs(b.start_time).unix() - dayjs(a.start_time).unix());
  }, [calls, startDate, endDate, selectedLocation]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(filteredCalls.length / rowsPerPage);
  }, [filteredCalls.length, rowsPerPage]);

  // Get current page calls
  const currentCalls = useMemo(() => {
    const startIdx = (currentPage - 1) * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    return filteredCalls.slice(startIdx, endIdx);
  }, [filteredCalls, currentPage, rowsPerPage]);

  // Group current page calls by date (UTC)
  const groupedCalls = useMemo(() => {
    const groups = {};
    currentCalls.forEach((call) => {
      const date = dayjs.utc(call.start_time).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(call);
    });
    return groups;
  }, [currentCalls]);

  const handlePageChange = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  const handleLocationChange = useCallback((event) => {
    setSelectedLocation(event.target.value);
    setCurrentPage(1); // Reset page
  }, []);

  // Function to download Excel file
  const downloadExcel = useCallback(async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Call List');

      worksheet.columns = [
        { header: t('status'), key: 'status', width: 15 },
        { header: t('caller_number'), key: 'caller_number', width: 20 },
        { header: t('called_number'), key: 'called_number', width: 20 },
        { header: t('forwarded_to'), key: 'forwarded_to', width: 20 },
        { header: t('call_started'), key: 'call_started', width: 25 },
        { header: t('call_ended'), key: 'call_ended', width: 25 },
        { header: t('forwarded_call_duration'), key: 'forwarded_call_duration', width: 20 },
        { header: t('call_duration'), key: 'call_duration', width: 20 },
      ];

      // Header styling
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, size: 12 };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      // Add data
      filteredCalls.forEach((call) => {
        // Määritetään, onko puhelu siirretty
        const isForwarded =
          call.forwarded_to_number &&
          call.forwarded_to_number !== 'N/A' &&
          call.forwarded_call_duration &&
          call.forwarded_call_duration !== 'N/A';

        // Hae puhelun aikavyöhyke
        const callTimezone = locationTimezones[call.location_id] || 'UTC';

        const row = worksheet.addRow({
          status: isForwarded ? t('forwarded_call') : t('regular_call'),
          caller_number: call.caller_number,
          called_number: call.called_number,
          forwarded_to: call.forwarded_to_number || 'N/A',
          call_started: dayjs.utc(call.start_time).tz(callTimezone).format('YYYY-MM-DD HH:mm:ss'),
          call_ended: call.end_time
            ? dayjs.utc(call.end_time).tz(callTimezone).format('YYYY-MM-DD HH:mm:ss')
            : 'N/A',
          forwarded_call_duration: call.forwarded_call_duration
            ? formatDurationToString(call.forwarded_call_duration)
            : 'N/A',
          call_duration: formatDurationToString(call.duration),
        });

        // Alternate row styling
        if (row.number % 2 === 0) {
          row.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF2F2F2' },
            };
          });
        }

        // Cell borders
        row.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });

      // Auto column width
      worksheet.columns.forEach((column) => {
        if (!column.width) {
          column.width = 15;
        }
      });

      // Write to buffer
      const buffer = await workbook.xlsx.writeBuffer();

      // Create Blob and download
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const currentDate = dayjs().format('YYYY-MM-DD');
      saveAs(blob, `call_list_${currentDate}.xlsx`);
    } catch (error) {
      console.error('Virhe Excel-tiedoston lataamisessa:', error);
      setError(t('error_downloading_excel'));
      setSnackbar({ open: true, message: t('error_downloading_excel'), severity: 'error' });
    }
  }, [filteredCalls, formatDurationToString, locationTimezones, t]);

  // Function to handle refresh
  const handleRefresh = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all([fetchCalls(), fetchLocations()]);
      setLoading(false);
    } catch (err) {
      console.error('Virhe päivittäessä dataa:', err);
      setError(t('error_refreshing_data'));
      setLoading(false);
      setSnackbar({ open: true, message: t('error_refreshing_data'), severity: 'error' });
    }
  }, [fetchCalls, fetchLocations, t]);

  // Handle Snackbar Close
  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  // Päivitä dayjs locale ja LocalizationProvider adapterLocale
  useEffect(() => {
    const currentLanguage = i18n.language || 'fi';
    dayjs.locale(currentLanguage);
  }, [i18n.language]);

  // Määritä adapterLocale dynaamisesti
  const adapterLocale = i18n.language || 'fi';

  // ### JSX Rendering ###

  // Conditional Rendering for Loading and Error States
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress aria-label={t('loading')} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          padding: 3,
          backgroundColor: 'background.default',
          borderRadius: 2,
          boxShadow: 1,
          minHeight: '100vh',
        }}
      >
        <Typography
          variant="h6"
          color="error"
          sx={{ textAlign: 'center', marginTop: 4 }}
        >
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: 'background.default',
        borderRadius: 2,
        boxShadow: 1,
        minHeight: '100vh',
      }}
    >
      {/* Lisää tilaa navigaatiomenuun */}
      <Box sx={(theme) => theme.mixins.toolbar} />

      {/* Filters */}
      <Filters
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        locations={locations}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        downloadExcel={downloadExcel}
        handleRefresh={handleRefresh}
        loading={loading}
        t={t}
        adapterLocale={adapterLocale}
      />

      {/* Call List Table */}
      <CallListTable
        groupedCalls={groupedCalls}
        formatDurationToString={formatDurationToString}
        locationTimezones={locationTimezones}
        t={t}
      />

      {/* Pagination */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          showFirstButton
          showLastButton
          aria-label={t('pagination')}
        />
      </Box>

      {/* Snackbar for Notifications */}
      <ErrorSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        handleClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default CallList;
