// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { WebSocketProvider } from './WebSocketContext';
import App from './App';
import './index.css';
import './i18n'; // Lisää i18n-konfiguraatio


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WebSocketProvider>
    <App />
  </WebSocketProvider>
);
