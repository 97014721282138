// src/components/Report.js

import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Divider,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { WebSocketContext } from '../WebSocketContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fi';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTheme } from '@mui/material/styles';

dayjs.extend(utc);
dayjs.extend(timezone);

function Report() {
  const { t, i18n } = useTranslation();
  const { subscribe } = useContext(WebSocketContext);
  const theme = useTheme();

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [stats, setStats] = useState({
    total_calls: 0,
    total_call_requests: 0,
    handled_call_requests: 0,
    unhandled_call_requests: 0,
    average_call_duration: 0,
    positive_feedbacks: 0,
    negative_feedbacks: 0,
    translated_calls: 0,
  });
  const [feedbackStats, setFeedbackStats] = useState({
    bad: 0,
    moderate: 0,
    good: 0,
  });
  const [loadingStats, setLoadingStats] = useState(false);
  const [error, setError] = useState(null);

  const [startDate, setStartDate] = useState(dayjs().subtract(3, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));

  const isFetching = useRef(false);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const formatDate = useCallback((date) => {
    return date.utc().format();
  }, []);

  const fetchStats = useCallback(async () => {
    if ((selectedLocation && startDate && endDate) || selectedLocation === '') {
      setLoadingStats(true);
      setError(null);
      isFetching.current = true;

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const params = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };

      if (selectedLocation) {
        params.location_id = selectedLocation;
      }

      try {
        if (selectedLocation) {
          const [statsResponse, feedbackResponse] = await Promise.all([
            axios.get('/api/reports/stats', { params }),
            axios.get('/api/reports/feedback_stats', { params }),
          ]);
          console.log('Stats Response:', statsResponse.data);
          setStats(statsResponse.data);
          setFeedbackStats(feedbackResponse.data);
        } else {
          if (locations.length === 0) {
            setError(t('no_locations_available'));
            setSnackbar({ open: true, message: t('no_locations_available'), severity: 'error' });
            setLoadingStats(false);
            isFetching.current = false;
            return;
          }

          const statsPromises = locations.map((location) =>
            axios.get('/api/reports/stats', {
              params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate,
                location_id: location.id,
              },
            })
          );

          const feedbackStatsPromises = locations.map((location) =>
            axios.get('/api/reports/feedback_stats', {
              params: {
                start_date: formattedStartDate,
                end_date: formattedEndDate,
                location_id: location.id,
              },
            })
          );

          const [statsResponses, feedbackStatsResponses] = await Promise.all([
            Promise.all(statsPromises),
            Promise.all(feedbackStatsPromises),
          ]);

          const aggregatedStats = statsResponses.reduce(
            (acc, response) => {
              const data = response.data;
              acc.total_calls += data.total_calls;
              acc.total_call_requests += data.total_call_requests;
              acc.handled_call_requests += data.handled_call_requests;
              acc.unhandled_call_requests += data.unhandled_call_requests;
              acc.average_call_duration += data.average_call_duration * data.total_calls;
              acc.positive_feedbacks += data.positive_feedbacks;
              acc.negative_feedbacks += data.negative_feedbacks;
              acc.translated_calls += data.translated_calls;
              return acc;
            },
            {
              total_calls: 0,
              total_call_requests: 0,
              handled_call_requests: 0,
              unhandled_call_requests: 0,
              average_call_duration: 0,
              positive_feedbacks: 0,
              negative_feedbacks: 0,
              translated_calls: 0,
            }
          );

          aggregatedStats.average_call_duration =
            aggregatedStats.total_calls > 0
              ? aggregatedStats.average_call_duration / aggregatedStats.total_calls
              : 0;

          const aggregatedFeedbackStats = feedbackStatsResponses.reduce(
            (acc, response) => {
              const data = response.data;
              acc.good += data.good;
              acc.moderate += data.moderate;
              acc.bad += data.bad;
              return acc;
            },
            { good: 0, moderate: 0, bad: 0 }
          );

          setStats(aggregatedStats);
          setFeedbackStats(aggregatedFeedbackStats);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(t('error_fetching_stats'));
        setSnackbar({ open: true, message: t('error_fetching_stats'), severity: 'error' });
      } finally {
        setLoadingStats(false);
        isFetching.current = false;
      }
    }
  }, [selectedLocation, startDate, endDate, formatDate, t, locations]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('/api/locations');
        setLocations(response.data);
        setSelectedLocation('');
      } catch (error) {
        console.error('Error fetching locations:', error);
        setError(t('error_fetching_locations'));
        setSnackbar({ open: true, message: t('error_fetching_locations'), severity: 'error' });
      }
    };

    fetchLocations();
  }, [t]);

  useEffect(() => {
    if (locations.length > 0) {
      fetchStats();
    }
  }, [fetchStats, locations.length]);

  useEffect(() => {
    const handleNewCall = (data) => {
      if (!selectedLocation || (data.location && data.location.id === selectedLocation)) {
        fetchStats();
      }
    };

    const handleNewCallRequest = (data) => {
      if (!selectedLocation || data.location_id === selectedLocation) {
        fetchStats();
      }
    };

    const handleUpdateCallRequest = (data) => {
      if (!selectedLocation || data.location_id === selectedLocation) {
        fetchStats();
      }
    };

    const unsubscribeNewCall = subscribe('new_call', handleNewCall);
    const unsubscribeNewCallRequest = subscribe('new_call_request', handleNewCallRequest);
    const unsubscribeUpdateCallRequest = subscribe('update_call_request', handleUpdateCallRequest);

    return () => {
      unsubscribeNewCall();
      unsubscribeNewCallRequest();
      unsubscribeUpdateCallRequest();
    };
  }, [selectedLocation, subscribe, fetchStats]);

  useEffect(() => {
    const currentLanguage = i18n.language || 'fi';
    dayjs.locale(currentLanguage);
  }, [i18n.language]);

  const currentLanguage = i18n.language || 'fi';
  const adapterLocale = currentLanguage === 'en' ? 'en' : 'fi';

  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <Box sx={{ padding: 3, backgroundColor: 'background.default', borderRadius: 2, boxShadow: 1 }}>
      <Box sx={theme.mixins.toolbar} />

      {/* Toimipistevalinta ja päivämäärävalitsimet */}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
        <Grid container spacing={2} sx={{ marginBottom: 4 }}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="location-select-label">{t('select_location')}</InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
                label={t('select_location')}
              >
                <MenuItem value="">
                  <em>{t('all_locations')}</em>
                </MenuItem>
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              label={t('start_date')}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              maxDate={endDate}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              label={t('end_date')}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={startDate}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => fetchStats()}
              fullWidth
              disabled={loadingStats}
            >
              {loadingStats ? <CircularProgress size={24} /> : t('refresh')}
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>

      {loadingStats ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            backgroundColor: 'background.default',
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="h6" color="error" sx={{ textAlign: 'center', marginTop: 4 }}>
          {error}
        </Typography>
      ) : stats ? (
        <>
          {/* Positiivinen, negatiivinen ja käsittelemättömät palautteen lukumäärät */}
          <Grid container spacing={3} sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" sx={{ marginBottom: 3 }}>
                    {t('call_request_feedbacks')}
                  </Typography>
                  <Grid container spacing={2} justifyContent="center">
                    {/* Positiivinen palaute */}
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                          <Typography variant="h6" color="success.main" align="center">
                            {t('positive_feedback')}
                          </Typography>
                          <Typography variant="h3" color="success.main" align="center">
                            {stats.positive_feedbacks}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* Negatiivinen palaute */}
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                          <Typography variant="h6" color="error.main" align="center">
                            {t('negative_feedback')}
                          </Typography>
                          <Typography variant="h3" color="error.main" align="center">
                            {stats.negative_feedbacks}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* Käsittelemättömät pyynnöt */}
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                          <Typography variant="h6" color="warning.main" align="center">
                            {t('unhandled_requests')}
                          </Typography>
                          <Typography variant="h3" color="warning.main" align="center">
                            {stats.unhandled_call_requests}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Divider sx={{ marginY: 4 }} />

          {/* Kaksi taulukkoa vierekkäin */}
          <Grid container spacing={3}>
            {/* Vasemmanpuoleinen taulukko: Puhelutilastot */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    {t('call_statistics')}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>{t('total_calls')}</TableCell>
                          <TableCell>{stats.total_calls}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('total_call_requests')}</TableCell>
                          <TableCell>{stats.total_call_requests}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('handled_call_requests')}</TableCell>
                          <TableCell>{stats.handled_call_requests}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('unhandled_call_requests')}</TableCell>
                          <TableCell>{stats.unhandled_call_requests}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('average_call_duration')}</TableCell>
                          <TableCell>
                            {stats.average_call_duration.toFixed(2)} {t('seconds')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('translated_calls')}</TableCell>
                          <TableCell>{stats.translated_calls}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            {/* Oikeanpuoleinen taulukko: SMS-tilastot */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    {t('sms_feedback')}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>{t('good_feedback')}</TableCell>
                          <TableCell>{feedbackStats.good}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('moderate_feedback')}</TableCell>
                          <TableCell>{feedbackStats.moderate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('bad_feedback')}</TableCell>
                          <TableCell>{feedbackStats.bad}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('total_feedbacks')}</TableCell>
                          <TableCell>
                            {feedbackStats.good + feedbackStats.moderate + feedbackStats.bad}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center', marginTop: 4 }}>
          {t('no_data')}
        </Typography>
      )}

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Report;
