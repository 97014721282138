// src/components/ControlPanel.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Typography, Button, Paper, TextField, Dialog, DialogActions,
  DialogContent, DialogTitle, Select, MenuItem, InputLabel, FormControl,
  Tabs, Tab, Box, Grid, IconButton, Tooltip,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Autocomplete,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import timezones from '../timezones'; // Import timezones from a separate file

const ControlPanel = () => {
  const { t } = useTranslation();
  
  const [selectedCorporation, setSelectedCorporation] = useState(null);
  const [corporations, setCorporations] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);

  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogMode, setDialogMode] = useState('add'); // 'add' or 'edit'
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [newEntity, setNewEntity] = useState({});

  // Hae autentikointitoken paikallisesta tallennustilasta
  const authToken = localStorage.getItem('token');

  // Funktio konsernien hakemiseen
  const fetchCorporations = useCallback(async () => {
    try {
      const response = await axios.get('/api/corporations/', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCorporations(response.data);
    } catch (error) {
      console.error('Virhe haettaessa konserneja:', error);
      // Voit lisätä virheilmoituksen käyttäjälle tässä
    }
  }, [authToken]);

  // Hakee valitun konsernin alaiset yhtiöt, toimipaikat ja käyttäjät
  const fetchData = useCallback(async () => {
    if (!selectedCorporation) {
      // Tyhjennetään aiemmat tiedot, jos konsernia ei ole valittu
      setCompanies([]);
      setLocations([]);
      setUsers([]);
      return;
    }

    try {
      const [companiesRes, locationsRes, usersRes] = await Promise.all([
        axios.get(`/api/corporations/${selectedCorporation.id}/companies/`, {
          headers: { Authorization: `Bearer ${authToken}` },
        }),
        axios.get(`/api/corporations/${selectedCorporation.id}/locations/`, {
          headers: { Authorization: `Bearer ${authToken}` },
        }),
        axios.get('/users/', {
          headers: { Authorization: `Bearer ${authToken}` },
        }),
      ]);

      setCompanies(companiesRes.data);
      setLocations(locationsRes.data);
      setUsers(usersRes.data);

      // Debug: Tulostetaan haettu data
      console.log('Fetched companies:', companiesRes.data);
      console.log('Fetched locations:', locationsRes.data);
      console.log('Fetched users:', usersRes.data);

    } catch (error) {
      console.error('Virhe haettaessa tietoja:', error);
      // Voit lisätä virheilmoituksen käyttäjälle tässä
    }
  }, [selectedCorporation, authToken]);

  // Ladataan konsernit komponentin ladatessa
  useEffect(() => {
    fetchCorporations();
  }, [fetchCorporations]);

  // Ladataan tiedot, kun valittu konserni muuttuu
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCorporationChange = (event) => {
    const corporation = corporations.find((corp) => corp.id === event.target.value);
    setSelectedCorporation(corporation);
    setTabIndex(3); // Vaihdetaan käyttäjät-välilehdelle
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOpenDialog = (type, mode = 'add', entity = null) => {
    setDialogType(type);
    setDialogMode(mode);
    setSelectedEntity(entity);
    setNewEntity(mode === 'edit' ? { ...entity } : {});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCreateOrUpdateEntity = async () => {
    try {
      switch (dialogType) {
        case 'corporation':
          if (dialogMode === 'add') {
            await axios.post('/api/corporations/', newEntity, {
              headers: { Authorization: `Bearer ${authToken}` },
            });
          } else {
            await axios.put(`/api/corporations/${selectedEntity.id}/`, newEntity, {
              headers: { Authorization: `Bearer ${authToken}` },
            });
          }
          await fetchCorporations();
          break;
        case 'company':
          if (dialogMode === 'add') {
            await axios.post(
              `/api/corporations/${selectedCorporation.id}/companies/`,
              { ...newEntity, corporation_id: selectedCorporation.id },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
          } else {
            await axios.put(
              `/api/corporations/${selectedCorporation.id}/companies/${selectedEntity.id}/`,
              newEntity,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
          }
          await fetchData();
          break;
        case 'location':
          if (dialogMode === 'add') {
            await axios.post(
              `/api/corporations/${selectedCorporation.id}/locations/`,
              newEntity,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
          } else {
            await axios.put(
              `/api/corporations/${selectedCorporation.id}/locations/${selectedEntity.id}/`,
              newEntity,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
          }
          await fetchData();
          break;
        case 'user':
          if (dialogMode === 'add') {
            await axios.post('/users/', { ...newEntity, corporation_id: selectedCorporation.id }, { // Lisätty corporation_id
              headers: { Authorization: `Bearer ${authToken}` },
            });
          } else {
            await axios.put(`/users/${selectedEntity.id}/`, newEntity, {
              headers: { Authorization: `Bearer ${authToken}` },
            });
          }
          await fetchData();
          break;
        default:
          break;
      }
      handleCloseDialog();
    } catch (error) {
      console.error(
        `Virhe ${dialogMode === 'add' ? 'luotaessa' : 'päivitettäessä'} ${dialogType}:`,
        error
      );
      // Voit lisätä virheilmoituksen käyttäjälle tässä
    }
  };

  const handleDeleteEntity = async (type, id) => {
    if (!window.confirm(t('confirm_delete'))) return;
    try {
      switch (type) {
        case 'corporation':
          await axios.delete(`/api/corporations/${id}/`, {
            headers: { Authorization: `Bearer ${authToken}` },
          });
          await fetchCorporations();
          if (selectedCorporation && selectedCorporation.id === id) {
            setSelectedCorporation(null);
          }
          break;
        case 'company':
          await axios.delete(
            `/api/corporations/${selectedCorporation.id}/companies/${id}/`,
            {
              headers: { Authorization: `Bearer ${authToken}` },
            }
          );
          await fetchData();
          break;
        case 'location':
          await axios.delete(
            `/api/corporations/${selectedCorporation.id}/locations/${id}/`,
            {
              headers: { Authorization: `Bearer ${authToken}` },
            }
          );
          await fetchData();
          break;
        case 'user':
          await axios.delete(`/users/${id}/`, {
            headers: { Authorization: `Bearer ${authToken}` },
          });
          await fetchData();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Virhe poistettaessa ${type}:`, error);
      // Voit lisätä virheilmoituksen käyttäjälle tässä
    }
  };

  // Lisää useMemo suodattaaksesi käyttäjät valitun corporationin perusteella
  const filteredUsers = useMemo(() => {
    if (!selectedCorporation || locations.length === 0) return [];
    
    // Muutetaan location_id merkkijonoiksi
    const selectedLocationIds = new Set(locations.map(loc => String(loc.id)));
    console.log('Selected Location IDs:', selectedLocationIds); // Debug-tulostus
    
    // Suodatetaan käyttäjät
    const filtered = users.filter(user => {
      const hasLocation = user.locations?.some(loc => selectedLocationIds.has(String(loc.id)));
      console.log(`User ${user.username} has location in selected:`, hasLocation); // Debug-tulostus
      return hasLocation;
    });
    
    console.log('Filtered Users:', filtered); // Debug-tulostus
    return filtered;
  }, [users, locations, selectedCorporation]);

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        // Konsernit-välilehti
        return (
          <>
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '16px' }}>
              <Typography variant="h6">{t('corporations')}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog('corporation', 'add')}
                startIcon={<AddIcon />}
              >
                {t('add_corporation')}
              </Button>
            </Grid>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('name_label')}</TableCell>
                      <TableCell>{t('business_id_label')}</TableCell>
                      <TableCell>{t('contact_person_label')}</TableCell>
                      <TableCell>{t('contact_email_label')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {corporations.map((corp) => (
                      <TableRow
                        key={corp.id}
                        selected={selectedCorporation && corp.id === selectedCorporation.id}
                        onClick={() => setSelectedCorporation(corp)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell>{corp.name}</TableCell>
                        <TableCell>{corp.business_id}</TableCell>
                        <TableCell>{corp.contact_person}</TableCell>
                        <TableCell>{corp.contact_email}</TableCell>
                        <TableCell>
                          <Tooltip title={t('edit')}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDialog('corporation', 'edit', corp);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('delete')}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteEntity('corporation', corp.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        );
      case 1:
        // Yhtiöt-välilehti
        if (!selectedCorporation) {
          return (
            <Typography variant="h6" color="textSecondary" align="center" style={{ marginTop: '20px' }}>
              {t('select_corporation_to_view_companies')}
            </Typography>
          );
        }
        return (
          <>
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '16px' }}>
              <Typography variant="h6">{t('companies')}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog('company', 'add')}
                startIcon={<AddIcon />}
              >
                {t('add_company')}
              </Button>
            </Grid>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('name_label')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies.map((company) => (
                      <TableRow key={company.id}>
                        <TableCell>{company.name}</TableCell>
                        <TableCell>
                          <Tooltip title={t('edit')}>
                            <IconButton onClick={() => handleOpenDialog('company', 'edit', company)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('delete')}>
                            <IconButton onClick={() => handleDeleteEntity('company', company.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        );
      case 2:
        // Toimipaikat-välilehti
        if (!selectedCorporation) {
          return (
            <Typography variant="h6" color="textSecondary" align="center" style={{ marginTop: '20px' }}>
              {t('select_corporation_to_view_locations')}
            </Typography>
          );
        }
        return (
          <>
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '16px' }}>
              <Typography variant="h6">{t('locations_tab')}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog('location', 'add')}
                startIcon={<AddIcon />}
              >
                {t('add_location')}
              </Button>
            </Grid>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('company_label')}</TableCell>
                      <TableCell>{t('name_label')}</TableCell>
                      <TableCell>{t('phone_number_label')}</TableCell>
                      <TableCell>{t('timezone_label')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locations.map((location) => (
                      <TableRow key={location.id}>
                        <TableCell>{location.company ? location.company.name : ''}</TableCell>
                        <TableCell>{location.name}</TableCell>
                        <TableCell>{location.phone_number}</TableCell>
                        <TableCell>{location.timezone}</TableCell>
                        <TableCell>
                          <Tooltip title={t('edit')}>
                            <IconButton onClick={() => handleOpenDialog('location', 'edit', location)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('delete')}>
                            <IconButton onClick={() => handleDeleteEntity('location', location.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        );
      case 3:
        // Käyttäjät-välilehti
        return (
          <>
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '16px' }}>
              <Typography variant="h6">{t('users_tab')}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog('user', 'add')}
                startIcon={<AddIcon />}
              >
                {t('add_user')}
              </Button>
            </Grid>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('username_label')}</TableCell>
                      <TableCell>{t('role_label')}</TableCell>
                      <TableCell>{t('locations_label')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>
                          {user.is_sys_admin
                            ? t('user_role_sys_admin')
                            : user.is_admin
                            ? t('user_role_admin')
                            : t('user_role_user')}
                        </TableCell>
                        <TableCell>
                          {user.locations.map((loc) => loc.name).join(', ')}
                        </TableCell>
                        <TableCell>
                          <Tooltip title={t('edit')}>
                            <IconButton onClick={() => handleOpenDialog('user', 'edit', user)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('delete')}>
                            <IconButton onClick={() => handleDeleteEntity('user', user.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    {filteredUsers.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          {t('no_data')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="control-panel-container">
      {/* Konsernin valinta */}
      <FormControl fullWidth margin="dense">
        <InputLabel>{t('select_corporation')}</InputLabel>
        <Select
          value={selectedCorporation ? selectedCorporation.id : ''}
          onChange={handleCorporationChange}
        >
          {corporations.map((corp) => (
            <MenuItem key={corp.id} value={corp.id}>
              {corp.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Paper square style={{ marginTop: '16px' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={t('corporations')} />
          <Tab label={t('companies')} />
          <Tab label={t('locations_tab')} />
          <Tab label={t('users_tab')} />
        </Tabs>
      </Paper>
      <Box mt={2}>{renderTabContent()}</Box>

      {/* Dialogi entiteettien lisäämiseen ja muokkaamiseen */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>{`${t(dialogMode === 'add' ? 'add_new' : 'edit')} ${
          dialogType === 'corporation'
            ? t('corporations')
            : dialogType === 'company'
            ? t('companies')
            : dialogType === 'location'
            ? t('locations_tab')
            : dialogType === 'user'
            ? t('users_tab')
            : ''
        }`}</DialogTitle>
        <DialogContent>
          {dialogType === 'corporation' && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label={t('name_label')}
                fullWidth
                value={newEntity.name || ''}
                onChange={(e) => setNewEntity({ ...newEntity, name: e.target.value })}
              />
              <TextField
                margin="dense"
                label={t('business_id_label')}
                fullWidth
                value={newEntity.business_id || ''}
                onChange={(e) => setNewEntity({ ...newEntity, business_id: e.target.value })}
              />
              <TextField
                margin="dense"
                label={t('contact_person_label')}
                fullWidth
                value={newEntity.contact_person || ''}
                onChange={(e) => setNewEntity({ ...newEntity, contact_person: e.target.value })}
              />
              <TextField
                margin="dense"
                label={t('contact_phone_label')}
                fullWidth
                value={newEntity.contact_phone || ''}
                onChange={(e) => setNewEntity({ ...newEntity, contact_phone: e.target.value })}
              />
              <TextField
                margin="dense"
                label={t('contact_email_label')}
                fullWidth
                value={newEntity.contact_email || ''}
                onChange={(e) => setNewEntity({ ...newEntity, contact_email: e.target.value })}
              />
            </>
          )}
          {dialogType === 'company' && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label={t('name_label')}
                fullWidth
                value={newEntity.name || ''}
                onChange={(e) => setNewEntity({ ...newEntity, name: e.target.value })}
              />
              {/* Lisää muut kentät tarvittaessa */}
            </>
          )}
          {dialogType === 'location' && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label={t('name_label')}
                fullWidth
                value={newEntity.name || ''}
                onChange={(e) => setNewEntity({ ...newEntity, name: e.target.value })}
              />
              <TextField
                margin="dense"
                label={t('phone_number_label')}
                fullWidth
                value={newEntity.phone_number || ''}
                onChange={(e) => setNewEntity({ ...newEntity, phone_number: e.target.value })}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>{t('company_label')}</InputLabel>
                <Select
                  value={newEntity.company_id || ''}
                  onChange={(e) => setNewEntity({ ...newEntity, company_id: e.target.value })}
                >
                  {companies.map((comp) => (
                    <MenuItem key={comp.id} value={comp.id}>
                      {comp.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Lisätään aikavyöhykkeen valinta */}
              <FormControl fullWidth margin="dense">
                <Autocomplete
                  options={timezones}
                  getOptionLabel={(option) => option}
                  value={newEntity.timezone || ''}
                  onChange={(event, newValue) => {
                    setNewEntity({ ...newEntity, timezone: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('timezone_label')}
                      margin="normal"
                    />
                  )}
                />
              </FormControl>
            </>
          )}
          {dialogType === 'user' && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label={t('username_label')}
                fullWidth
                value={newEntity.username || ''}
                onChange={(e) => setNewEntity({ ...newEntity, username: e.target.value })}
                disabled={dialogMode === 'edit'}
              />
              <TextField
                margin="dense"
                label={t('password_label')}
                type="password"
                fullWidth
                value={newEntity.password || ''}
                onChange={(e) => setNewEntity({ ...newEntity, password: e.target.value })}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>{t('locations_label')}</InputLabel>
                <Select
                  multiple
                  value={newEntity.location_ids || []}
                  onChange={(e) => setNewEntity({ ...newEntity, location_ids: e.target.value })}
                  renderValue={(selected) =>
                    locations
                      .filter((loc) => selected.includes(loc.id))
                      .map((loc) => loc.name)
                      .join(', ')
                  }
                >
                  {locations.map((loc) => (
                    <MenuItem key={loc.id} value={loc.id}>
                      {loc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>{t('role_label')}</InputLabel>
                <Select
                  value={
                    newEntity.is_sys_admin
                      ? 'sys_admin'
                      : newEntity.is_admin
                      ? 'admin'
                      : 'user'
                  }
                  onChange={(e) => {
                    const role = e.target.value;
                    setNewEntity({
                      ...newEntity,
                      is_sys_admin: role === 'sys_admin',
                      is_admin: role === 'admin',
                    });
                  }}
                >
                  <MenuItem value="user">{t('user_role_user')}</MenuItem>
                  <MenuItem value="admin">{t('user_role_admin')}</MenuItem>
                  <MenuItem value="sys_admin">{t('user_role_sys_admin')}</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleCreateOrUpdateEntity} color="primary">
            {dialogMode === 'add' ? t('create_successful') : t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ControlPanel;
