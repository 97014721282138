// src/contexts/AppContext.js

import { createContext } from 'react';

const AppContext = createContext({
  isAdmin: false,
  isSysAdmin: false,
  authToken: null, // Lisää authToken
  setAuthToken: () => {},
  userName: '', // Lisää userName
  setUserName: () => {}, // Lisää setUserName
});

export default AppContext;
